import { Icon, IconProps } from "@chakra-ui/react";

export function NotFound(props: IconProps) {
  return (
    <Icon width={585} height={352} viewBox="0 0 585 352" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M166.546 206.839h-19.864V94.367H72.058L4.145 211.939v41.339h83.75V282h58.787v-28.722h19.864v-46.439zm-112.204 0l35.701-63.349c-1.342 12.616-2.147 26.037-2.147 44.022v19.327H54.342zM577.731 206.839h-19.864V94.367h-74.624L415.33 211.939v41.339h83.75V282h58.787v-28.722h19.864v-46.439zm-112.205 0l35.702-63.349c-1.342 12.616-2.148 26.037-2.148 44.022v19.327h-33.554z"
        fill="#813131"
      />
      <path stroke="#B5A7A7" strokeWidth={39.47} strokeLinecap="round" d="M251.189 129.327l131.523 194.268" />
      <path
        d="M291.092 278.058c-51.591 0-93.301-41.711-93.301-93.143 0-51.591 41.711-93.302 93.301-93.302 51.434 0 93.302 41.712 93.302 93.302 0 51.588-41.866 93.143-93.302 93.143z"
        fill="#B5A7A7"
        stroke="#B5A7A7"
        strokeWidth={1.581}
      />
      <path
        d="M291.07 245.278c-33.403 0-60.615-27.068-60.615-60.615 0-33.546 27.068-60.614 60.615-60.614 33.403 0 60.614 27.068 60.614 60.614 0 33.547-27.211 60.615-60.614 60.615z"
        fill="#813131"
      />
      <path
        d="M291.07 245.278c-28.88 0-53.14-20.156-59.205-47.223 6.065-27.067 30.325-47.223 59.205-47.223s53.14 20.156 59.205 47.223c-6.065 27.067-30.325 47.223-59.205 47.223z"
        fill="#F4D0C0"
      />
      <path
        d="M309.395 242.387c-5.753 1.878-12.082 2.889-18.555 2.889-28.768 0-52.934-20.225-58.975-47.384 5.035-22.537 22.439-40.307 44.735-45.652-5.898 9.391-9.206 20.37-9.206 32.216 0 27.16 17.692 50.13 42.001 57.931z"
        fill="#E95848"
      />
    </Icon>
  );
}
